import axios from "axios";
import { url } from "../../BaseUrl";

const APIBaseUrl = url;

export const createLabelAPI = async (token,payload) => {
    const response = await axios
        .post(`${APIBaseUrl}/v1/label/create`,payload, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            },
        })
        .then((response) => {
            return response.data;
        })
        .catch((error) => ({ error: error }));
    return response;
};

export const fetchLabelAPI = async (token,type) => {
    const response = await axios
        .get(`${APIBaseUrl}/v1/label/find?label_type=`+type, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            },
        })
        .then((response) => {
            return response.data;
        })
        .catch((error) => ({ error: error }));
    return response;
};

// export const createAPI = async (token,path,payload) => {
//     const response = await axios
//         .post(`${APIBaseUrl}`+path,payload, {
//             headers: {
//                 Accept: "application/json",
//                 "Content-Type": "application/json",
//                 "Authorization": "Bearer " + token
//             },
//         })
//         .then((response) => {
//             return response.data; 
//         })
//         .catch((error) => ({ error: error }));
//     return response;
// };
export const createWithoutTokenAPI = async (path, payload) => {
    try {
      const isFormData = payload instanceof FormData;
  
      const headers = {
        Accept: 'application/json',
      };
  
      // Only set 'Content-Type' if payload is not FormData
      if (!isFormData) {
        headers['Content-Type'] = 'application/json';
      }
  
      const response = await axios.post(`${APIBaseUrl}${path}`, payload, { headers });
  
      return response.data;
    } catch (error) {
      // Handle error appropriately
      console.error('API request failed:', error);
      return { error: error };
    }
  };
export const createAPI = async (token, path, payload) => {
    try {
      const isFormData = payload instanceof FormData;
  
      const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      };
  
      // Only set 'Content-Type' if payload is not FormData
      if (!isFormData) {
        headers['Content-Type'] = 'application/json';
      }
  
      const response = await axios.post(`${APIBaseUrl}${path}`, payload, { headers });
  
      return response.data;
    } catch (error) {
      // Handle error appropriately
      console.error('API request failed:', error);
      return { error: error };
    }
  };
  
export const updateAPI = async (token,path,payload) => {
    const response = await axios
        .put(`${APIBaseUrl}`+path,payload, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            },
        })
        .then((response) => {
            return response.data;
        })
        .catch((error) => ({ error: error }));
    return response;
};

export const fetchWithQueryAPI = async (token,path) => {
    const response = await axios
        .get(`${APIBaseUrl}`+path, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            },
        })
        .then((response) => {
            return response.data;
        })
        .catch((error) => ({ error: error }));
    return response;
};

export const deleteAPI = async (token,path,payload) => {
    const response = await axios
        .delete(`${APIBaseUrl}`+path, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            },
            data: payload
        })
        .then((response) => {
            return response.data;
        })
        .catch((error) => ({ error: error }));
    return response;
};


export const UploadDocument = async (Payload) => {
    const response = await axios
      .post(`${APIBaseUrl}/upload`, Payload, {
        // headers: {
        //   Accept: "application/json",
        //   "Content-Type": "application/json",
        // },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => ({ error: error }));
    return response;
};
